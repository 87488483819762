import { ListItem, Button } from "@chakra-ui/react";

type SearchResultProps = SearchResultActionProps & {
  name: string;
  yearsActive: string;
};

type SearchResultActionProps = {
  onClick: () => void;
  alreadyUsed: boolean;
  alreadyGuessed: boolean;
};

function SearchResult({ name, yearsActive, ...props }: SearchResultProps) {
  const textColor = props.alreadyGuessed ? "text-red-600" : "text-black";
  return (
    <ListItem>
      <div className="m-3 flex justify-between align-items">
        <div>
          <h3 className={`text-lg ${textColor}`}>{name}</h3>
          <p className={`text-sm ${textColor}`}>{yearsActive}</p>
        </div>
        <SearchResultAction {...props} />
      </div>
    </ListItem>
  );
}

function SearchResultAction({
  onClick,
  alreadyUsed,
  alreadyGuessed,
}: SearchResultActionProps) {
  if (alreadyUsed) {
    return <p>Already Used</p>;
  }

  if (alreadyGuessed) {
    return null;
  }

  return (
    <Button onClick={() => onClick()} className="my-auto" colorScheme="green">
      Select
    </Button>
  );
}

export default SearchResult;
