import { Box, Image } from "@chakra-ui/react";
import { Action, BoxName, GameState, PlayerBoxes } from "../types";

type PlayerBoxProps = {
  selectedBox: GameState["selectedBox"];
  onBoxClick: React.Dispatch<Action>;
  boxName: BoxName;
  playerBoxes: PlayerBoxes;
  percentageCompleted: string;
};

type BorderStyles = { [K in BoxName]: string };

const borderStyles: BorderStyles = {
  TOP_LEFT: "rounded-tl-lg border border-r-0",
  TOP_MIDDLE: "border",
  TOP_RIGHT: "rounded-tr-lg border border-l-0",
  MIDDLE_LEFT: "border-l ",
  MIDDLE_MIDDLE: "border-l border-r",
  MIDDLE_RIGHT: "border-r",
  BOTTOM_LEFT: "rounded-bl-lg border border-r-0",
  BOTTOM_MIDDLE: "border",
  BOTTOM_RIGHT: "rounded-br-lg border border-l-0",
};

function PlayerBox({
  selectedBox,
  boxName,
  onBoxClick,
  playerBoxes,
  percentageCompleted,
}: PlayerBoxProps) {
  const isSelected = selectedBox === boxName;
  const player = playerBoxes[boxName];

  const bgStyle = isSelected
    ? "bg-amber-300"
    : player
    ? "bg-green-400"
    : "bg-white hover:bg-slate-200";

  return (
    <button
      disabled={!!player}
      onClick={() => onBoxClick({ type: "BOX_SELECT", box: boxName })}
      className={`w-56 h-56 relative ${borderStyles[boxName]} ${bgStyle}`}
    >
      {player ? (
        <>
          <div className="absolute flex-none bg-gray-900 opacity-95 top-0 right-0 px-1.5 rounded-bl-lg">
            <p className="text-white text-lg">{percentageCompleted}</p>
          </div>
          <Box className="h-full w-full overflow flex flex-col">
            <div className="grow flex justify-center items-center">
              <Image
                className="object-none object-center rounded-lg"
                src={`https://cms.nhl.bamgrid.com/images/headshots/current/168x168/${player.playerId}.jpg`}
              />
            </div>

            <div className="flex-none bg-gray-900	opacity-95">
              <p className="text-white text-lg">{player.playerName}</p>
            </div>
          </Box>
        </>
      ) : null}
    </button>
  );
}
export default PlayerBox;
