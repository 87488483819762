import React, { useEffect, useState } from "react";
import SearchInput from "./components/SearchInput";
import PlayerBox from "./components/PlayerBox";
import BoxRowWrapper from "./components/BoxRowWrapper";
import RemainingGuesses from "./components/RemainingGuesses";
import useGameState from "./hooks/useGameState";
import FinishedModal from "./components/FinishedModal";
import CategoryBox from "./components/CategoryBox";

function Gameboard() {
  const { state, dispatch } = useGameState();

  const [showFinishedModal, setShowFinishedModal] = useState(false);

  const {
    searchQuery,
    selectedBox,
    gameState,
    playerBoxes,
    playersUsed,
    guesses,
  } = state;

  useEffect(() => {
    if (gameState === "finished") {
      setShowFinishedModal(true);
    }
  }, [gameState]);

  return (
    <>
      <SearchInput
        searchQuery={searchQuery}
        selectedBox={selectedBox}
        playersUsed={playersUsed}
        dispatch={dispatch}
      />

      <FinishedModal
        showFinishedModal={showFinishedModal}
        setShowFinishedModal={setShowFinishedModal}
      />

      <div className="flex justify-center align-center mt-12">
        <div>
          <div className="mt-3">
            <BoxRowWrapper>
              <CategoryBox teamAbbreviation="" />
              <CategoryBox teamAbbreviation="VGK" />
              <CategoryBox teamAbbreviation="NYR" />
              <CategoryBox teamAbbreviation="CAR" />
            </BoxRowWrapper>

            <BoxRowWrapper>
              <CategoryBox teamAbbreviation="STL" />
              <PlayerBox
                boxName="TOP_LEFT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="90%"
              />
              <PlayerBox
                boxName="TOP_MIDDLE"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="5%"
              />
              <PlayerBox
                boxName="TOP_RIGHT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="20%"
              />
            </BoxRowWrapper>

            <BoxRowWrapper>
              <CategoryBox teamAbbreviation="EDM" />
              <PlayerBox
                boxName="MIDDLE_LEFT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="30%"
              />
              <PlayerBox
                boxName="MIDDLE_MIDDLE"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="56%"
              />
              <PlayerBox
                boxName="MIDDLE_RIGHT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="63%"
              />
            </BoxRowWrapper>

            <BoxRowWrapper>
              <CategoryBox teamAbbreviation="SEA" />
              <PlayerBox
                boxName="BOTTOM_LEFT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="28%"
              />
              <PlayerBox
                boxName="BOTTOM_MIDDLE"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="93%"
              />
              <PlayerBox
                boxName="BOTTOM_RIGHT"
                selectedBox={selectedBox}
                onBoxClick={dispatch}
                playerBoxes={playerBoxes}
                percentageCompleted="21%"
              />
            </BoxRowWrapper>
          </div>

          <RemainingGuesses
            remainingGuesses={guesses}
            setShowFinishedModal={setShowFinishedModal}
          />
        </div>
      </div>
    </>
  );
}

export default Gameboard;
