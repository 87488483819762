import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

type FinshedModalProps = {
  showFinishedModal: boolean;
  setShowFinishedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function FinishedModal({
  showFinishedModal,
  setShowFinishedModal,
}: FinshedModalProps) {
  return (
    <Modal
      isOpen={showFinishedModal}
      onClose={() => setShowFinishedModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Finished</ModalHeader>
        <ModalCloseButton />
        <ModalBody>You have finished. You suck!</ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FinishedModal;
