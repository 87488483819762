import { useReducer } from "react";
import { Action, GameState } from "../types";

const defaultState: GameState = {
  gameState: "inprogress",
  searchQuery: "",
  selectedBox: undefined,
  guesses: 9,
  playersUsed: [],
  playerBoxes: {
    TOP_LEFT: undefined,
    TOP_MIDDLE: undefined,
    TOP_RIGHT: undefined,
    MIDDLE_LEFT: undefined,
    MIDDLE_MIDDLE: undefined,
    MIDDLE_RIGHT: undefined,
    BOTTOM_LEFT: undefined,
    BOTTOM_MIDDLE: undefined,
    BOTTOM_RIGHT: undefined,
  },
};

function gameStateReducer(state: GameState, action: Action): GameState {
  const { type } = action;

  switch (type) {
    case "BOX_SELECT": {
      if (state.gameState === "finished") {
        return state;
      }

      return {
        ...state,
        selectedBox: action.box,
      };
    }
    case "INPUT_ON_CHANGE": {
      return {
        ...state,
        searchQuery: action.value,
      };
    }
    case "CORRECT_GUESS": {
      const remainingGuesses = state.guesses - 1;

      return {
        ...state,
        searchQuery: "",
        selectedBox: undefined,
        playersUsed: [...state.playersUsed, action.player.playerId],
        playerBoxes: { ...state.playerBoxes, [action.box]: action.player },
        guesses: remainingGuesses,
        gameState: remainingGuesses === 0 ? "finished" : "inprogress",
      };
    }
    case "INVALID_GUESS": {
      const remainingGuesses = state.guesses - 1;

      return {
        ...state,
        guesses: remainingGuesses,
        gameState: remainingGuesses === 0 ? "finished" : "inprogress",
        selectedBox: remainingGuesses <= 0 ? undefined : state.selectedBox,
      };
    }
    case "CLOSE_SEARCH": {
      return {
        ...state,
        selectedBox: undefined,
      };
    }
  }

  throw new Error("Action not found");
}

function useGameState() {
  const [state, dispatch] = useReducer(gameStateReducer, defaultState);

  return { state, dispatch };
}

export default useGameState;
