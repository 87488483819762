import { Button } from "@chakra-ui/react";

type RemainingGuessesProps = {
  remainingGuesses: number;
  setShowFinishedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function RemainingGuesses({
  remainingGuesses,
  setShowFinishedModal,
}: RemainingGuessesProps) {
  return (
    <div className="mt-12 flex justify-center align-items ml-56">
      <div>
        <p className="text-2xl">Guesses</p>
        <h1 className="font-bold text-7xl">{remainingGuesses}</h1>

        {remainingGuesses === 0 ? (
          <Button onClick={() => setShowFinishedModal(true)} colorScheme="blue">
            Show stats
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default RemainingGuesses;
