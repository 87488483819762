import React from "react";

function Header() {
  return (
    <div className="flex flex-row justify-between items-center">
      <p>Hockey grid</p>
      <p>Follow me on: twitter</p>
      <p>Stats</p>
      <p>? - how to play</p>
    </div>
  );
}

export default Header;
