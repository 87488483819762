import { Input } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, List } from "@chakra-ui/react";
import { Action, GameState, Player } from "../types";
import SearchResult from "./SearchResult";

type SearchInputProps = {
  selectedBox: GameState["selectedBox"];
  searchQuery: string;
  dispatch: React.Dispatch<Action>;
  playersUsed: string[];
};

const testResults = [
  {
    playerId: "8474565",
    playerName: "Alex Pietrangelo",
    yearsActive: "2008 - Present",
  },
  {
    playerId: "8477402",
    playerName: "Pavel Buchnevich",
    yearsActive: "2016 - Present",
  },
  {
    playerId: "8479323",
    playerName: "Adam Fox",
    yearsActive: "2019 - Present",
  },
];

function SearchInput({
  selectedBox,
  searchQuery,
  dispatch,
  playersUsed,
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [incorrectSearches, setIncorrectSearches] = useState<string[]>([]);

  function onSearchResultSelect(player: Player) {
    if (selectedBox === "TOP_LEFT" && player.playerId === "8474565") {
      dispatch({ type: "CORRECT_GUESS", box: selectedBox, player });
    } else if (selectedBox === "TOP_MIDDLE" && player.playerId === "8477402") {
      dispatch({ type: "CORRECT_GUESS", box: selectedBox, player });
    } else {
      setIncorrectSearches((searches) => [...searches, player.playerId]);
      dispatch({ type: "INVALID_GUESS" });
    }
  }

  useEffect(() => {
    // clears incorrect searches when the modal is closed
    if (!selectedBox && incorrectSearches.length > 0) {
      setIncorrectSearches([]);
    }
  }, [incorrectSearches.length, selectedBox]);

  return (
    <Modal
      size={"2xl"}
      isOpen={!!selectedBox}
      onClose={() => dispatch({ type: "CLOSE_SEARCH" })}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalBody> */}
        <Input
          backgroundColor={"white"}
          contentEditable={!!selectedBox}
          ref={inputRef}
          value={searchQuery}
          placeholder="Search for a player"
          onChange={(e) =>
            dispatch({ type: "INPUT_ON_CHANGE", value: e.target.value })
          }
        />

        <List spacing={4}>
          {testResults.map((result, i) => (
            <SearchResult
              key={`search-result-${i}`}
              name={result.playerName}
              yearsActive={result.yearsActive}
              alreadyUsed={playersUsed.includes(result.playerId)}
              alreadyGuessed={incorrectSearches.includes(result.playerId)}
              onClick={() => onSearchResultSelect(result)}
            />
          ))}
        </List>
      </ModalContent>
    </Modal>
  );
}

export default SearchInput;
