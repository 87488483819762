import { Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

type CategoryBoxProps = {
  teamAbbreviation?: string;
};

function CategoryBox({ teamAbbreviation }: CategoryBoxProps) {
  return (
    <Box className="w-56 h-56 flex justify-center items-center">
      {teamAbbreviation ? (
        <Image
          className="w-10/12"
          src={`https://cdn.nhle.com/logos/nhl/svg/${teamAbbreviation}_light.svg`}
        />
      ) : null}
    </Box>
  );
}
export default CategoryBox;
